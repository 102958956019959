import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import GrandisImage from "../../assets/images/grandis-rooms.jpeg";
import GardenImage from "../../assets/images/garden-rooms.jpeg";
import PanoramicaImage from "../../assets/images/pano-rooms.jpeg";
import MicaImage from "../../assets/images/mica-rooms.jpeg";
import SticlaImage from "../../assets/images/sticla-rooms.jpeg";
import "../../assets/OurRooms.css";
import AOS from "aos";
import "aos/dist/aos.css";

const roomsData = [
  {
    title: "Sala Grandis",
    description:
      "Capacitate: 150 - 500 persoane <br> Piscina Decorativa <br/> Mobilier exclusivist <br/> Ecran LED <br/> Lumini Artistice <br/> Sunet",
    imageUrl: GrandisImage,
    link: "/sala-grandis",
  },
  {
    title: "Sala Garden View",
    description:
      "Capacitate: 30 - 50 persoane <br/> Mobilier Exclusivist <br/> Lumini Artistice <br/> Terasa",
    imageUrl: GardenImage,
    link: "/sala-garden-view",
  },
  {
    title: "Sala Panoramica",
    description:
      "Capacitate: 100 - 200 persoane <br/> Acces zona verde <br/> Ecran LED <br/> Lumini Artistice",
    imageUrl: PanoramicaImage,
    link: "/sala-panoramica",
  },
  {
    title: "Sala Mica",
    description: "Capacitate: 50 - 80 persoane <br/> Terasa",
    imageUrl: MicaImage,
    link: "/sala-mica",
  },
  {
    title: "Sala De Sticla",
    description:
      "Capacitate: 20-40 persoane <br/> Terasa <br/> Acoperis panoramic de sticla",
    imageUrl: SticlaImage,
    link: "/sala-de-sticla",
  },
];

const OurRooms = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 }); // Inițializează AOS cu durata animațiilor
  }, []);
  const roomsRef = useRef(null); // Creăm ref-ul
  return (
    <div ref={roomsRef} className="rooms-container">
      <h2 className="rooms-title">Salile Noastre</h2>
      <div className="about-line"></div>
      <div className="rooms-grid">
        {roomsData.map((room, index) => (
          <div
            className={`room-card ${index % 2 !== 0 ? "reverse" : ""}`} // Alternăm ordinea pe baza indexului
            key={index}
            data-aos="fade-up" // Alege animația dorită
            data-aos-once="true"
          >
            <div className="room-image">
              <img src={room.imageUrl} alt={room.title} />
            </div>
            <div className="room-info">
              <h3 className="room-title">{room.title}</h3>
              <p
                className="room-description"
                dangerouslySetInnerHTML={{ __html: room.description }}
              ></p>
              <Link to={room.link} className="room-button">
                Vezi Sala
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurRooms;
