import React, { useState } from "react";
import "../../assets/Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className="navbar">
      {/* Logo */}
      <div className="navbar-brand">
        <a href="/">PREMIUM PARK</a>
      </div>

      {/* Burger Menu */}
      <div
        className={`menu-toggle ${menuOpen ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <div className="burger-line"></div>
        <div className="burger-line"></div>
        <div className="burger-line"></div>
      </div>

      {/* Link-uri */}
      <ul className={`navbar-links ${menuOpen ? "active" : ""}`}>
        <li>
          <Link to="/" onClick={closeMenu}>
            Acasă
          </Link>
        </li>
        <li>
          <Link to="salile-noastre" onClick={closeMenu}>
            Salile
          </Link>
        </li>
        <li>
          <Link to="servicii" onClick={closeMenu}>
            Servicii
          </Link>
        </li>
        <li>
          <Link to="evenimente" onClick={closeMenu}>
            Evenimente
          </Link>
        </li>
        <li>
          {" "}
          <Link to="contact" onClick={closeMenu}>
            Contacte
          </Link>{" "}
        </li>
      </ul>
      {/* Close button for menu */}
      {menuOpen && (
        <div className="close-menu" onClick={toggleMenu}>
          X
        </div>
      )}
    </nav>
  );
};

export default Navbar;
