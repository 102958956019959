import React from "react";
import "../../assets/AboutUs.css";

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="about-content">
        <h2 className="about-title">Despre Noi</h2>
        <div className="about-line"></div>
        <p className="about-intro">
          Complexul nostru este un loc ideal pentru organizarea de evenimente
          speciale, oferind o gamă variată de servicii și 5 restaurante
          elegante, fiecare cu un ambient unic, perfect pentru orice tip de
          eveniment. Situat la doar 5 km de oraș, suntem într-o locație
          accesibilă, dar într-un cadru liniștit, care garantează intimitatea și
          confortul necesar pentru o experiență memorabilă.
        </p>
        <p className="about-description"></p>
        <p className="about-mission">
          Activăm din 2015 în domeniul organizării de evenimente, având o echipă
          profesionistă și dedicată, care se asigură că fiecare detaliu este
          perfect pus la punct. Indiferent că vorbim de nunți, botezuri,
          aniversări sau evenimente corporative, complexul nostru este locul
          unde visele devin realitate.
        </p>
      </div>
      <div className="about-image">
        <img
          src="https://premiumclient-huqx.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fdespre3.b1ca8c04.jpg&w=384&q=75"
          alt="Despre Noi"
        />
      </div>
    </div>
  );
};

export default AboutUs;
