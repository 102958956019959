import React, { useEffect } from "react";
import "../../assets/OurServices.css";
import AOS from "aos";
import "aos/dist/aos.css";
import FursetSarat from "../../assets/images/furset-sarat.jpeg";
import FruitBar from "../../assets/images/fruit-bar.jpeg";
import Turn from "../../assets/images/turn.jpeg";
import Tort from "../../assets/images/tort.jpeg";
import Catering from "../../assets/images/catering.jpeg";
import Cafea from "../../assets/images/cafea.jpeg";
import Lumini from "../../assets/images/lumini.jpeg";
import Fum from "../../assets/images/fum.jpeg";

const servicesData = [
  {
    title: "Fourchette Sarat",
    description:
      "Unicitate si exclusivitate <br/> Perfect pentru intampinarea oaspetilor <br/> Personalizare conform preferintelor oaspetilor <br/> Evenimente de tip fourchette",
    imageUrl: FursetSarat,
  },
  {
    title: "Fruit Bar",
    description:
      "Fresh și rafinat, perfect pentru un moment de răsfăț <br/>Un colț plin de culoare și savoare, ideal pentru oaspeții care apreciază gusturile naturale.",
    imageUrl: FruitBar,
  },
  {
    title: "Turn din pahare de Prosecco",
    description:
      "Eleganță și rafinament în fiecare pahar <br/> Un turn de pahare delicate, umplut cu Prosecco, va adăuga un strop de magie și sofisticare evenimentului tău",
    imageUrl: Turn,
  },
  {
    title: "Tortul Mirelui",
    description:
      "Simbol al iubirii și al tradiției <br/> Un tort magnific, personalizat pentru a reflecta stilul și gusturile cuplului. Un moment deosebit care va înfrumuseța evenimentul și va încânta toți invitații.",
    imageUrl: Tort,
  },
  {
    title: "Catering",
    description:
      "Delicii culinare personalizate pentru fiecare gust <br/> Meniu exclusiv, creat pentru a satisface toate preferințele, de la preparate tradiționale la delicatese internaționale.",
    imageUrl: Catering,
  },
  {
    title: "Cafea si Ceai",
    description:
      "Relaxare și rafinament pentru oaspeții tăi <br/> Oferă-i fiecărui invitat o experiență relaxantă cu o selecție de ceaiuri fine și cafea de specialitate",
    imageUrl: Cafea,
  },
  {
    title: "Lumini artistice, sunet si Ecran LED",
    description:
      "Crează atmosfera perfectă cu tehnologie de ultimă generație <br/> Un spectacol de lumini și sunet care transformă orice eveniment într-o experiență memorabilă.",
    imageUrl: Lumini,
  },
  {
    title: "Fum greu si Artificii reci",
    description:
      "Un final spectaculos, cu un efect vizual de neuitat <br/> Fumul greu și artificiile vor adăuga un strop de magie și dramă evenimentului tău.",
    imageUrl: Fum,
  },
];

const OurServices = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 }); // Inițializează AOS cu durata animațiilor
  }, []);
  return (
    <div data-aos="zoom-in" data-aos-once="true" className="services-container">
      <h2 className="services-title">Serviciile Noastre</h2>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div className="service-card" key={index}>
            <div className="service-image">
              <img src={service.imageUrl} alt={service.title} />
            </div>
            <div className="service-info">
              <h3 className="service-title">{service.title}</h3>
              <p
                className="service-description"
                dangerouslySetInnerHTML={{ __html: service.description }}
              ></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurServices;
